import styles from "./Result.module.css";

const Result = (props) => {
    const link = "https://mempool.space/block/" + String(props.height);

    const calculateTime = (timestamp) => {
        const date = new Date(timestamp * 1000);
        const hours = date.getHours();
        let minutes = date.getMinutes();
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        if (minutes < 10) {
            minutes = `0${minutes}`;
        }
        const timeString = `${year}/${month}/${day} - ${hours}:${minutes}`;
        return timeString;
    };

    return (
        <div className={styles.resultContainer}>
            <div className={styles.resultBlock}>
                <a
                    className={styles.resultBlockText}
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                >
                    {props.height}
                </a>
            </div>
            <div className={styles.timeContainer}>
                <div className={styles.resultTime}>
                    {`Median Timestamp: ${calculateTime(props.mediantime)}`}
                </div>
                <div className={styles.resultTime}>
                {`Miner Timestamp: ${calculateTime(props.time)}`}
                </div>
            </div>
        </div>
    );
};

export default Result;
