import React from "react";
import styles from "./Switch.module.css";

const Switch = (props) => {

    return (
        <label className={styles['toggle-switch']}>
            <input type="checkbox" checked={props.checked} onChange={props.onChange} />
            <span className={styles.switch}/>
        </label>
    );
};

export default Switch;
