import { useEffect, useState } from "react";
import styles from "./App.module.css";
import DonationModal from "./components/DonationModal/DonationModal";
import ResultList from "./components/Results/ResultList";
import ResultListBefore from "./components/Results/ResultsBefore/ResultListBefore";
import ResultListFuture from "./components/Results/ResultsFuture/ResultListFuture";

import Switch from "./components/Switch/Switch";

function App() {
    const [selectedDate, setSelectedDate] = useState("2012-12-21");
    const [selectedTime, setSelectedTime] = useState("21:21");
    const [error, setError] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [fetchedBlocks, setFetchedBlock] = useState();
    const [beforeGenesis, setBeforeGenesis] = useState();
    const [futureBlocks, setFutureBlocks] = useState();
    const [currentTip, setCurrentTip] = useState();
    const [isTime, setIsTime] = useState(false);

    useEffect(() => {
        fetch("https://blocktimebackend.herokuapp.com/api/v1/tip")
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setCurrentTip(data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);

    const onToggle = () => {
        setIsTime(!isTime);
    };

    const medianGenesis = 1231006505;

    const dateChangeHandler = (event) => {
        setSelectedDate(event.target.value);
    };

    const getBlocks = (startStamp, endStamp, isTime) => {
        let orderBy = "";
        if (isTime === true) {
            orderBy = "time";
        } else {
            orderBy = "mediantime";
        }
        const dbURL = "https://blocktimebackend.herokuapp.com/api/v1/range";

        fetch(
            String(dbURL) +
                "?orderBy=" +
                String(orderBy) +
                "&startAt=" +
                String(startStamp) +
                "&endAt=" +
                String(endStamp)
        )
            .then((response) => response.json())
            .then((data) => {
                const array = [];
                for (const key in data) {
                    array.push(data[key]);
                }
                setFetchedBlock(array);
            })
            .catch((err) => {
                console.log(err.message);
            });
    };

    const timeChangeHandler = (event) => {
        setSelectedTime(event.target.value);
    };

    const clickHandler = () => {
        if (
            selectedDate === undefined ||
            selectedTime === undefined ||
            selectedDate === "" ||
            selectedTime === ""
        ) {
            setError(true);
        } else {
            setError(false);
            const timeData =
                selectedDate.replace(/-/g, "/") + " " + selectedTime;
            const timestamp = Math.floor(new Date(timeData).getTime() / 1000);
            if (timestamp < medianGenesis) {
                const difference = medianGenesis - timestamp;
                const blocksBeforeGenesis = Math.ceil(difference / 600);
                setBeforeGenesis(blocksBeforeGenesis);
                setFetchedBlock();
                setFutureBlocks();
            } else if (!isTime && timestamp > currentTip.medianTimestamp) {
                const difference =
                    timestamp - Number(currentTip.medianTimestamp);
                setFutureBlocks(Math.ceil(difference / 600));
                setFetchedBlock();
                setBeforeGenesis();
            } else if (isTime && timestamp > currentTip.minerTimestamp) {
                const difference =
                    timestamp - Number(currentTip.minerTimestamp);
                setFutureBlocks(Math.ceil(difference / 600));
                setFetchedBlock();
                setBeforeGenesis();
            } else {
                getBlocks(timestamp - 1800, timestamp + 1800, isTime);
                setBeforeGenesis();
                setFutureBlocks();
            }
        }
    };

    const showDonationHandler = () => {
        setShowModal(true);
    };

    const hideDonationHandler = () => {
        setShowModal(false);
    };

    return (
        <>
            <div className={styles.App}>
                <div className={styles.appHeader}>
                    <div className={styles.appButtons}>
                        <div className={styles.appButton}></div>
                        <div className={styles.appButton}></div>
                        <div className={styles.appButton}></div>
                    </div>
                    <div className={styles.appTitle}>
                        <h1 className={styles.title}>blocktime.site</h1>
                    </div>
                    <div className={styles.appButtonsHidden}>
                        <div className={styles.appButtonHidden}></div>
                        <div className={styles.appButtonHidden}></div>
                        <div className={styles.appButtonHidden}></div>
                    </div>
                </div>
                {showModal && <DonationModal onClick={hideDonationHandler} />}
                <div className={styles.selection}>
                    <input
                        type="date"
                        value={selectedDate}
                        onChange={dateChangeHandler}
                        className={
                            error
                                ? `${styles.dateSelect} ${styles.invalid}`
                                : styles.dateSelect
                        }
                    ></input>
                    <input
                        type="time"
                        value={selectedTime}
                        onChange={timeChangeHandler}
                        className={
                            error
                                ? `${styles.timeSelect} ${styles.invalid}`
                                : styles.timeSelect
                        }
                    ></input>
                </div>
                {error && (
                    <p className={styles.errorMessage}>
                        Please make sure to enter a valid date and time
                    </p>
                )}
                <p className={styles.UTCinfo}>
                    Entered date and time will be in your browsers timezone.
                    Select what should be used to fetch blocks:
                </p>
                <div className={styles.switchContainer}>
                    <p
                        className={
                            isTime
                                ? `${styles.switchText}`
                                : `${styles.switchText} ${styles.switchTextActive}`
                        }
                    >
                        Mediantime
                    </p>
                    <Switch onChange={onToggle} checked={isTime} />
                    <p
                        className={
                            isTime
                                ? `${styles.switchText} ${styles.switchTextActive}`
                                : `${styles.switchText}`
                        }
                    >
                        Timestamp
                    </p>
                </div>
                <button onClick={clickHandler} className={styles.mainbutton}>
                    Submit
                </button>
            </div>
            {futureBlocks && (
                <div className={styles.beforeGenesisContainer}>
                    <ResultListFuture
                        data={[
                            {
                                height: currentTip.height + futureBlocks,
                                mediantime: "",
                            },
                        ]}
                    />
                </div>
            )}
            {beforeGenesis && (
                <div className={styles.beforeGenesisContainer}>
                    <ResultListBefore
                        data={[{ height: beforeGenesis, mediantime: "" }]}
                    />
                </div>
            )}
            {fetchedBlocks && <ResultList data={fetchedBlocks} />}
            <div className={styles.donationFooter}>
                <p>
                    Built by <a href="https://twitter.com/Egge21M">Egge</a> with
                    🧡 v1.3.0
                </p>
                <p
                    onClick={showDonationHandler}
                    className={styles.donationParagraph}
                >
                    Buy me a coffee
                </p>
            </div>
        </>
    );
}

export default App;
