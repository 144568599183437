import { useState } from "react";
import styles from "./DonationModal.module.css";
import QRCode from "react-qr-code";
import satsSymbol from "../../SatsSymbol.png";

const DonationForm = (props) => {
    return (
        <div>
            <input
                type="number"
                className={styles.donationInput}
                onChange={props.onChange}
                placeholder="Input Amount"
            ></input>
        </div>
    );
};

const DonationModal = (props) => {
    const [donationAmount, setDonationAmount] = useState(0);
    const [donationInvoice, setDonationInvoice] = useState();
    const [copied, setCopied] = useState(false);

    const invoiceFetcher = (donationAmount) => {
        fetch(
            "https://starbackr.me/process-static-charges/egge" +
                "?amount=" +
                String(donationAmount * 1000)
        )
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                console.log(data.pr);
                setDonationInvoice(data.pr);
            })
            .catch((err) => {
                console.log(err.message);
            });
    };

    const formSubmitHandler = (event) => {
        invoiceFetcher(donationAmount);
    };

    const onChangeHandler = (event) => {
        setDonationAmount(event.target.value);
    };

    const copyHandler = (text) => {
        navigator.clipboard.writeText(text);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 1000);
    };

    const onChainHandler = () => {
        // fetch(
        //     "https://tallycoin.app/api/v3/payment/request?page_tag='yu600y'&satoshi_amount=2211"
        // )
        //     .then((response) => response.json())
        //     .then((data) => {
        //         console.log(data);
        //         console.log(data);
        //         copyHandler(data);
        //     })
        //     .catch((err) => {
        //         console.log(err.message);
        //     });
        copyHandler("bc1qfkk85xwfu095uqzjdd24u736ud5wdvpxw2gw5l");
    };

    return (
        <>
            <div className={styles.modalBackdrop}></div>
            <div className={styles.modal}>
                <div className={styles.header}>
                    <h1>Support this project</h1>
                </div>
                {donationInvoice && (
                    <div className={styles.donationInvoice}>
                        <QRCode
                            value={donationInvoice}
                            className={styles.qrcode}
                            title={donationInvoice}
                        />
                        <div className={styles.qrcodeIcon}>Test</div>
                        {copied && <p className={styles.copyText}>Copied!</p>}
                        <button
                            onClick={() => {
                                copyHandler(donationInvoice);
                            }}
                            className={styles.invoiceCopy}
                        >
                            Copy
                        </button>
                    </div>
                )}
                <div className={styles.donationInputContainer}>
                    <DonationForm onChange={onChangeHandler} />
                    <img src={satsSymbol} alt="" className={styles.sats}></img>
                </div>
                {copied && <p className={styles.copyText}>Copied!</p>}
                <div className={styles.actions}>
                    <div className={styles.donationButtons}>
                        <button onClick={formSubmitHandler}>Lightning</button>
                        <button onClick={onChainHandler}>On Chain</button>
                    </div>
                    <button onClick={props.onClick}>Close</button>
                </div>
            </div>
        </>
    );
};

export default DonationModal;
