import styles from "./ResultFuture.module.css";

const Result = (props) => {
    return (
        <div className={styles.resultContainer}>
            <div className={styles.resultBlock}>
                <p className={styles.resultBlockText}>
                    {`${props.height}`}
                </p>
            </div>
        </div>
    );
};

export default Result;
