import React from "react";
import Result from "./Result";
import styles from "./ResultList.module.css";

const ResultList = (props) => {
    return (
        <div>
            <div className={styles.resultListContainer}>
                {props.data.map((block) => {
                    return (
                        <Result
                            height={block.height}
                            mediantime={block.medianTimestamp}
                            time={block.minerTimestamp}
                            key={`${block.height}-${block.medianTimestamp}`}
                        ></Result>
                    );
                })}
            </div>
        </div>
    );
};

export default ResultList;
