import React from 'react'
import ResultFuture from './ResultFuture'
import styles from './ResultListFuture.module.css'

const ResultList = (props) => {
  return (
    <div>
       <p className={styles.beforeGenesisText}>
                        The date you selected was{" "}
                        <strong>not yet indexed</strong>. It might be not part of the blockchain yet.<br/><br/>
                        Below is an estimated, future blockheight based on 10 minutes
                        between blocks.
                    </p>
        <div className={styles.resultListContainer}>
            {props.data.map((block) => {return (
                <ResultFuture height={block.height} time={block.mediantime} key={`${block.height}-${block.mediantime}`}></ResultFuture>
            )})}
        </div>
    </div>
  )
}

export default ResultList