import React from 'react'
import ResultBefore from './ResultBefore'
import styles from './ResultListBefore.module.css'

const ResultList = (props) => {
  return (
    <div>
        <div className={styles.resultListContainer}>
        <p className={styles.beforeGenesisText}>
                        The date you selected was{" "}
                        <strong>before Genesis</strong> (Bitcoin's first block).
                        Below is an estimated blockheight based on 10 minutes
                        between blocks.
                    </p>
            {props.data.map((block) => {return (
                <ResultBefore height={block.height} time={block.mediantime} key={`${block.height}-${block.mediantime}`}></ResultBefore>
            )})}
        </div>
    </div>
  )
}

export default ResultList